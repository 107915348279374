/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;




@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.raleway {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.raleway-500 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}


.raleway-600 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.raleway-800 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}


.hand {
  position: relative;
  height: 100px;
}

.card {
  font-size:22px;
  height:50px;
  width:35px;
  border: 3px solid #64748b;
  border-radius: 8px;
  background-color: #f1f5f9;
  position: absolute;
  top: 0;
  transition: transform 0.2s ease; 
  display: flex;
  justify-content: center;
  align-items: center;
  transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);


  
  span {
    background-color: #8badc8;
    display:none;
    margin:0 auto;
    width:50px;
    height: 50px;
    border-radius: 25px;
  }
  
  &.card-1 {
    z-index:3;
    margin-left: 20px;
    animation-duration: 6s;
    animation-name: card-1;
    animation-iteration-count: infinite;
  }
  &.card-2 {
    z-index:2;
    margin-left:10px;
    animation-duration: 6s;
    animation-name: card-2;
    animation-iteration-count: infinite;
  }
  &.card-3 {
    z-index: 1;
    animation-duration: 6s;
    animation-name: card-3;
    animation-iteration-count: infinite;
  }
}

@keyframes card-1 {
  0% {}
  16.66666% {transform: translateX(95px) rotate(15deg)} 
    33.33333%  {transform: translateX(-20px); z-index:1} 
  49.99999% {transform: translateX(-20px); z-index:1}
  66.66666% {transform: translateX(-10px); z-index:2} 
  83.33333% {transform: translateX(-10px); z-index:2}
}

@keyframes card-2 {
  0% {}
  16.66666% {transform: translateX(0)}
  33.33333%  {transform: translateX(10px); z-index:3} 
  49.99999% {transform: translateX(105px) rotate(15deg)} 
  66.66666% {transform: translateX(-10px); z-index:1} 
  83.33333% {transform: translateX(-10px); z-index:1} 
}

@keyframes card-3 {
  0% {z-index: 1} 
  16.66666% {transform: translateX(0)}
  33.33333%  {transform: translateX(10px); z-index:2} 
  49.99999% {transform: translateX(10px); z-index:2} 
  66.66666% {transform: translateX(20px); z-index:3} 
  83.33333% {transform: translateX(115px) rotate(15deg);} 
}




